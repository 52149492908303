import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import {
  getCheckoutSessionId,
  initializeStripe,
  setStripeSessionIdToLocalStorage,
} from '../utils/stripe'
import StockholmLogoSVG from '../components/svgs/StockholmLogoSVG'
import OfferTitle from '../components/offer/OfferTitle'
import OfferTimer from '../components/offer/OfferTimer'
import OfferPrice from '../components/offer/OfferPrice'
import OfferPriceWarning from '../components/offer/OfferPriceWarning'
import OfferTestimonial from '../components/offer/OfferTestimonial'
import OfferIncludes from '../components/offer/OfferIncludes'
import { Events, getGtmEventLabel } from '../utils/gtmHelper'
import Button from '../components/Button'
import GenericErrorMessage from '../components/GenericErrorMessage'
import {
  useQueryParams,
  returnToApplication,
  getOriginName,
  getDomainName,
  mailigenCheapOfferNeeded,
} from '../utils/offer'
import { triggerEvent } from '../lib/gtm'
import {
  PremiumOfferPerson,
  PremiumOfferPrice,
  PremiumOfferTitle,
} from '../utils/types/offer'

const Premium: React.FC = () => {
  const router = useRouter()
  const { t } = useTranslation('offer')
  const [isStripeLoading, setIsStripeLoading] = useState(false)
  const [isStripeError, setIsStripeError] = useState(false)
  const {
    gender,
    name,
    weight,
    measurementUnit,
    currency,
    userCategory,
    email,
    cheap,
  } = useQueryParams()
  const weightWithMeasurement = `${weight}${measurementUnit}`

  useEffect(() => {
    localStorage.clear()
  }, [])

  const setApiStripeError = () => {
    setIsStripeLoading(false)
    setIsStripeError(true)
    setTimeout(() => {
      setIsStripeError(false)
    }, 5000)
  }

  const acceptOffer = async () => {
    const eventLabel = getGtmEventLabel(Events.offerAccepted, router)
    triggerEvent(Events.offerAccepted, eventLabel)
    setIsStripeLoading(true)

    const userData = {
      gender: gender,
      name: name,
      weight: weight,
      measurementUnit: measurementUnit,
      currency: currency,
      userCategory: userCategory,
      email: email,
    }

    const originName = getOriginName(router)
    const domainName = getDomainName(router)
    const mailigenCheap = mailigenCheapOfferNeeded(router, cheap) && cheap

    const sessionId = await getCheckoutSessionId(
      router.locale,
      userData,
      originName,
      domainName,
      mailigenCheap
    )
    if (!sessionId) {
      setApiStripeError()
      return
    }

    setStripeSessionIdToLocalStorage(sessionId)

    const stripe = await initializeStripe()
    const result = await stripe.redirectToCheckout({ sessionId })

    if (result.error) {
      setApiStripeError()
    }
  }

  const declineOffer = () => {
    const eventLabel = getGtmEventLabel(Events.offerDeclined, router)
    triggerEvent(Events.offerDeclined, eventLabel)
    returnToApplication(router)
  }

  return (
    <div className="container">
      <GenericErrorMessage showErrorMessage={isStripeError} />
      <section className="header">
        <StockholmLogoSVG />
        <OfferTimer />
        <div className="specialistBlock">
          <div className="specialist">
            <Image
              className="specialist"
              layout="fill"
              objectFit="contain"
              quality={100}
              src="/assets/offer/specialist.jpg"
              alt="specialist"
            />
          </div>
          <span className="recommendation">{t('recommendation')}</span>
        </div>
        <div className="mainTitleWrapper">
          <OfferTitle
            titleType={PremiumOfferTitle.title1}
            styleClassName="mainTitle"
            gender={gender}
            weight={weightWithMeasurement}
            name={name}
          />
        </div>
        <Button
          buttonText={t('button_accept')}
          onClick={() => acceptOffer()}
          isLoading={isStripeLoading}
        />
        <OfferPrice priceType={PremiumOfferPrice.price1} />
        <OfferPriceWarning />
      </section>
      <section className="testimonial">
        <span className="title testimonialTitle">{t('testimonial.title')}</span>
        <OfferTestimonial
          person={PremiumOfferPerson.person1}
          gender={gender}
          measurementUnit={measurementUnit}
        />
        <OfferTestimonial
          person={PremiumOfferPerson.person2}
          gender={gender}
          measurementUnit={measurementUnit}
        />
        <OfferTitle
          titleType={PremiumOfferTitle.title2}
          gender={gender}
          weight={weightWithMeasurement}
          name={name}
        />
        <Button
          buttonText={t('button_accept')}
          onClick={() => acceptOffer()}
          isLoading={isStripeLoading}
        />
        <OfferPrice priceType={PremiumOfferPrice.price2} />
        <div className={`jump jump${gender}`}>
          <Image
            src={`/assets/offer/testimonial/${gender}/jump.jpg`}
            layout="fill"
            objectFit="cover"
            quality={100}
            alt="jump"
          />
        </div>
      </section>
      <section className="includes">
        <span className="title">{t('premium_includes.title')}</span>
        <OfferIncludes gender={gender} weight={weightWithMeasurement} />
        <OfferTitle
          titleType={PremiumOfferTitle.title3}
          gender={gender}
          weight={weightWithMeasurement}
          name={name}
        />
        <Button
          buttonText={t('button_accept')}
          onClick={() => acceptOffer()}
          isLoading={isStripeLoading}
        />
        <OfferPrice priceType={PremiumOfferPrice.price3} />
        <OfferPriceWarning />
      </section>
      <section className="testimonial">
        <OfferTestimonial
          person={PremiumOfferPerson.person3}
          gender={gender}
          measurementUnit={measurementUnit}
        />
      </section>
      <Button
        buttonText={t('button_decline', { weight: weightWithMeasurement })}
        styleClassName="outlinedButton"
        onClick={() => declineOffer()}
      />
    </div>
  )
}

export default Premium
