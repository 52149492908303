import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import { PremiumOfferPerson } from '../../utils/types/offer'

type Props = {
  person: PremiumOfferPerson
  gender: string
  measurementUnit: string
}

const OfferTestimonial: React.FC<Props> = ({
  person,
  gender,
  measurementUnit,
}) => {
  const { t } = useTranslation('offer')
  const weight = t(`testimonial.${person}.weight_${gender}.${measurementUnit}`)

  return (
    <div className="testimonialBlock">
      <div className="testimonialImageWrapper">
        <Image
          src={`/assets/offer/testimonial/${gender}/${person}.jpg`}
          className="testimonialImage"
          layout="fill"
          objectFit="cover"
          quality={100}
          alt={`${person}-${gender}-before-after`}
        />
      </div>
      <span className="testimonialSubTitle">
        {t(`testimonial.${person}.title_${gender}`, {
          weight,
        })}
      </span>
      <p className="testimonialText">
        {t(`testimonial.${person}.person_testimonial_${gender}`, {
          weight,
          unit: measurementUnit,
        })}
      </p>
    </div>
  )
}

export default OfferTestimonial
