import useTranslation from 'next-translate/useTranslation'
import { useOfferPrices } from '../../utils/offer'
import { useTimer } from './useTimer'

const OfferPriceWarning: React.FC = () => {
  const { t } = useTranslation('offer')
  const { timerValue } = useTimer()
  const { display_old_price } = useOfferPrices()

  if (timerValue < 1) {
    return null
  }

  return (
    <span className="subPrice">
      {t('offer_price.timer.price_3', { display_old_price })}
    </span>
  )
}

export default OfferPriceWarning
