import useTranslation from 'next-translate/useTranslation'

type Props = {
  showErrorMessage: boolean
}

const GenericErrorMessage: React.FC<Props> = ({ showErrorMessage }) => {
  const { t } = useTranslation('offer')

  if (!showErrorMessage) {
    return null
  }

  return <div className="errorMessage">{t('error_message')}</div>
}

export default GenericErrorMessage
