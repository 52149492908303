import Trans from 'next-translate/Trans'
import { useTimer } from './useTimer'

const OfferTimer: React.FC = () => {
  const { timerValue, renderTimer } = useTimer()

  if (timerValue < 1) {
    return null
  }

  return (
    <Trans
      i18nKey="offer:timer"
      components={[
        <span key="timer" className="timer" />,
        <>{renderTimer()}</>,
      ]}
    />
  )
}

export default OfferTimer
