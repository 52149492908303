import Image from 'next/image'
import { getIncludeImagePath } from '../../utils/offer'

type Props = {
  gender: string
  includeId: string
}

const IncludeImage: React.FC<Props> = ({ gender, includeId }) => {
  const filePath = getIncludeImagePath(includeId, gender)

  return (
    <Image
      src={`/assets/offer/includes/${filePath}.jpg`}
      layout="fill"
      objectFit="cover"
      quality={100}
      alt={includeId}
    />
  )
}

export default IncludeImage
