import { useState, useEffect } from 'react'
import {
  getTimerStartingValue,
  setLastChanceInSessionStorage,
  setTimerInSessionStorage,
} from '../../utils/timer'

type TimerValues = {
  timerValue: number
  renderTimer: () => string
}

const formatTimePart = (timePart: number): string =>
  timePart.toString().padStart(2, '0')

export const useTimer = (): TimerValues => {
  const [timerValue, setTimerValue] = useState(getTimerStartingValue())

  useEffect(() => {
    const timer =
      timerValue > 0 && setInterval(() => setTimerValue(timerValue - 1), 1000)
    setTimerInSessionStorage(timerValue)
    setLastChanceInSessionStorage(timerValue)
    return () => clearInterval(timer)
  }, [timerValue])

  const renderTimer = () => {
    const minutes: number = Math.floor(timerValue / 60)
    const seconds: number = timerValue - minutes * 60

    const timeString = `${formatTimePart(minutes)}:${formatTimePart(seconds)}`

    return timeString
  }

  return { timerValue, renderTimer }
}
