type Props = {
  styleClassName?: string
}

const Loading: React.FC<Props> = ({ styleClassName }) => {
  const loadingClassName =
    styleClassName === 'button small' ? 'loader-s' : 'loader-md'

  return <div className={loadingClassName}></div>
}

export default Loading
