import * as React from 'react'

const PremiumCrownSVG: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <defs>
        <path id="prefix__a" d="M.061 0h6.302v6.302H.061z" />
      </defs>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <path
          fill="#FFD217"
          d="M19.348 16.416l-4.204-6.058-4.202 6.058-4.848-6.406 1.34 12.116h15.13l1.342-12.116-4.558 6.406zm-5.906 1.563L15 15.846l1.559 2.132L15 20.11l-1.558-2.132zm-6.008 5.928h15.13v-.891H7.434z"
        />
        <path
          fill="#FFD217"
          d="M12.051 10.536c-.958 0-1.734.778-1.734 1.736 0-.958-.778-1.736-1.736-1.736.958 0 1.736-.775 1.736-1.733 0 .958.776 1.733 1.734 1.733"
        />
        <g transform="translate(16.876 6.094)">
          <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <path
            fill="#FFD217"
            d="M6.363 3.15a3.151 3.151 0 00-3.15 3.152A3.154 3.154 0 00.061 3.149 3.15 3.15 0 003.213 0a3.15 3.15 0 003.15 3.15"
            mask="url(#prefix__b)"
          />
        </g>
        <circle cx={15} cy={15} r={15} stroke="#FFD217" />
      </g>
    </svg>
  )
}

export default PremiumCrownSVG
