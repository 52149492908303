import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { useOfferPrices } from '../../utils/offer'
import { useTimer } from './useTimer'
import { PremiumOfferPrice } from '../../utils/types/offer'

type Props = {
  priceType: PremiumOfferPrice
}

const OfferPrice: React.FC<Props> = ({ priceType }) => {
  const { t } = useTranslation('offer')
  const { timerValue } = useTimer()
  const { discount_price, full_price, display_old_price } = useOfferPrices()

  const getOfferPrice = () => {
    if (priceType === PremiumOfferPrice.price2) {
      if (timerValue > 0) {
        return (
          <Trans
            i18nKey="offer:offer_price.timer.price_2"
            components={[<del key="strikethrough" />]}
            values={{ display_old_price, discount_price }}
          />
        )
      }

      return t(`offer_price.standard.price_2`, { full_price })
    }

    if (priceType === PremiumOfferPrice.price3) {
      if (timerValue > 0) {
        return t(`offer_price.timer.price_1`, { discount_price })
      }

      return t(`offer_price.standard.price_3`, { full_price })
    }

    if (timerValue > 0) {
      return t(`offer_price.timer.price_1`, { discount_price })
    }

    return t(`offer_price.standard.price_1`, { full_price })
  }

  return <span className="price">{getOfferPrice()}</span>
}

export default OfferPrice
