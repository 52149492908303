import Loading from './Loading'

type Props = {
  buttonText: string
  styleClassName?:
    | 'outlinedButton'
    | 'button small'
    | 'outlinedButton outlineSmall'
  onClick?: () => void
  isLoading?: boolean
}

const Button: React.FC<Props> = ({
  buttonText,
  styleClassName = 'button',
  onClick,
  isLoading,
}) => {
  return (
    <button className={styleClassName} onClick={onClick} disabled={isLoading}>
      {isLoading ? <Loading styleClassName={styleClassName} /> : buttonText}
    </button>
  )
}

export default Button
