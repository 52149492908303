import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { PremiumOfferTitle } from '../../utils/types/offer'
import { useTimer } from './useTimer'

type Props = {
  titleType: PremiumOfferTitle
  styleClassName?: string
  gender: string
  weight: string
  name: string
}

const OfferTitle: React.FC<Props> = ({
  titleType,
  styleClassName = '',
  gender,
  weight,
  name,
}) => {
  const { t } = useTranslation('offer')
  const { timerValue, renderTimer } = useTimer()

  const getOfferTitle = () => {
    if (titleType === PremiumOfferTitle.title2) {
      if (timerValue > 0) {
        return (
          <Trans
            i18nKey={`offer:title_2.timer.text_${gender}`}
            components={[
              <span key="timer" className="titleTimer">
                {renderTimer()}
              </span>,
            ]}
            values={{ weight }}
          />
        )
      }

      return t(`title_2.standard.text_${gender}`, { weight })
    }
    if (titleType === PremiumOfferTitle.title3) {
      if (timerValue > 0) {
        return (
          <Trans
            i18nKey="offer:title_3.timer"
            components={[
              <span key="timer" className="titleTimer">
                {renderTimer()}
              </span>,
            ]}
          />
        )
      }

      return t('title_3.standard')
    }

    return t('title_1', { name, weight })
  }

  return <p className={`title ${styleClassName}`}>{getOfferTitle()}</p>
}

export default OfferTitle
