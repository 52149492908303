import useTranslation from 'next-translate/useTranslation'
import PremiumCrownSVG from '../svgs/PremiumCrownSVG'
import IncludeImage from './IncludeImage'

type Props = {
  gender: string
  weight: string
}

const OfferIncludes: React.FC<Props> = ({ gender, weight }) => {
  const { t } = useTranslation('offer')

  const renderIncludes = () => {
    const includesTotal = 11
    const rows = []

    for (let i = 1; i < includesTotal; i++) {
      rows.push(
        <div className="includeItem" key={i}>
          <div className="includeLeft">
            <PremiumCrownSVG />
            <IncludeImage gender={gender} includeId={`include_${i}`} />
          </div>
          <div className="includeRight">
            <p className="includeTitle">
              {t(`premium_includes.include_${i}.title`, { weight })}
            </p>
            <p className="includeParagraph">
              {t(`premium_includes.include_${i}.content`, { weight })}
            </p>
          </div>
        </div>
      )
    }

    return rows
  }

  return <div className="includeList">{renderIncludes()}</div>
}

export default OfferIncludes
